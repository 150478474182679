import React, {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import UlpsPortalApiClient from "../UlpsPortalApiClient";
import {Alert, Spinner} from "react-bootstrap";

export default function Distributors() {

  const [distributors, setDistributors] = useState(null)
  const [isWaiting, setWaiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setWaiting(true);
    UlpsPortalApiClient.getDistributors()
      .then(data => {
        setWaiting(false);
        setDistributors((data));
      })
      .catch(err => {
        setWaiting(false)
        setErrorMessage(err.message);
      })
  }, []);

  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true
  }, {
    dataField: 'country',
    text: 'Country',
    sort: true
  }, {
    dataField: 'contactName',
    text: 'Contact name'
  }, {
    dataField: 'phone',
    text: 'Phone'
  }, {
    dataField: 'email',
    text: 'E-mail'
  }, {
    dataField: 'batchCount',
    text: 'Batch count',
    sort: true
  }
  ];

  const rowEvents = {
    onClick: (e, row) => {
      window.location.href = `#/distributor/?id=${row.id}`;
    }
  }

  const rowClasses = (row, idx) => {
    return row.disabledAt !== null ? "disabled" : "enabled";
  }

  if (isWaiting) {
    return (
      <>
        <br/><br/>
        <Spinner animation="border"/>
      </>
    )
  } else if (!distributors) {
    return (
      <>
        <br/><br/>
        <Alert variant={"danger"}>
          Unable to fetch distributors. {errorMessage}
        </Alert>
      </>

    )
  }

  return (
    <>
      <h2>Distributors</h2>
      <BootstrapTable keyField='id'
                      data={distributors}
                      columns={columns}
                      hover bootstrap4
                      rowEvents={rowEvents}
                      rowClasses={rowClasses}
      />
    </>
  )

}
