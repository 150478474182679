import {useEffect, useState} from "react";
import UlpsPortalApiClient from "../UlpsPortalApiClient";
import {Alert, Button, ButtonGroup, Spinner, Table} from "react-bootstrap";
import Dispatcher from "../dispatcher";
import {FluxEvent, Permission} from "../Enums";
import Util from "../Util";
import Constant from "../Constant";
import DistributorInfo from "../components/DistributorInfo";
import PermissionRequired from "../PermissionRequired";
import BackButton from "../components/BackButton";

export default function DistributorDetails() {
  const [id, setId] = useState(Util.getQuerySearch('id'))
  const [distributor, setDistributor] = useState(null);
  const [freshBatches, setFreshBatches] = useState(null);
  const [isWaiting, setWaiting] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    resetDistributor();

    const d = Dispatcher.register(payload => {
      if (payload.contentType === FluxEvent.URL_CHANGED) {
        setId(Util.getQuerySearch('id'));
      }
    });

    return () => {
      Dispatcher.unregister(d);
    };
  }, []);

  useEffect(() => {
    resetDistributor();
  }, [id])

  const resetDistributor = async () => {
    setErrorMessage(null);
    if (id) {
      setWaiting(true);
      try {
        setDistributor(await UlpsPortalApiClient.getDistributorDetails(id));
        setFreshBatches(await UlpsPortalApiClient.getFreshBatches(id));
        setWaiting(false);
      } catch (e) {
        setErrorMessage(e.message)
        setWaiting(false);
      }
    }
  }

  if (!id) return null;

  if (isWaiting) {
    return <Spinner animation="border" role="status" />
  } else if (!distributor) {
    return (
      <Alert variant={"danger"}>
        {errorMessage}
      </Alert>
    )
  } else {
    return (
      <>
        <DistributorInfo distributorInfo={distributor} freshBatches={freshBatches} />

        <ButtonGroup>
          <BackButton />

          <PermissionRequired requiredPermissions={[Permission.DELIVERY]} silent={true}>
            <Button variant="success" href={`#${Constant.url.LICENCE_GENERATION}?id=${id}`}>Generate licences</Button>
          </PermissionRequired>
          </ButtonGroup>
      </>
    );
  }

}
