import {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Util from "../Util";
import UlpsPortalApiClient from "../UlpsPortalApiClient";
import Constant from "../Constant";
import {Alert, Button, ButtonGroup, Form, InputGroup, Spinner} from "react-bootstrap";
import {LicenceForm} from "../components/LicenceForm";

export default function EditLicence() {

  const licenceId = Util.getQuerySearch("id");

    const [licence, setLicence] = useState(null);
    const [isWaiting, setWaiting] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const history = useHistory();

    useEffect(async () => {
      try {
        setWaiting(true);
        setLicence(await UlpsPortalApiClient.getLicence(licenceId));
        setWaiting(false)
      }
      catch (e) {
        setErrorMessage(e.message || "Unable to get licence.")
        setWaiting(false);
      }
    }, []);

    const handleSubmit = async evt => {
      console.log(evt)
      evt.preventDefault()
      setErrorMessage(null);
      setWaiting(true);
      try {
        await UlpsPortalApiClient.updateLicence(licenceId, Util.convertLicenceData(licence))
        setWaiting(false);
        history.push(`${Constant.url.LICENCES}/?id=${licenceId}&success=true`);
      } catch (e) {
        setErrorMessage(e.message || "Unable to generate licence.")
        setWaiting(false);
      }

    }

    return (
      <>
        <h2>Edit licence</h2>

        <Form onSubmit={handleSubmit}>

          {licence && <LicenceForm licence={licence} setLicence={setLicence} setLoading={setWaiting}/>}

          {
            isWaiting ? (
              <Spinner animation="border"/>
            ) : (
              <ButtonGroup>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button
                  variant="light"
                  onClick={() => history.goBack()}
                >
                  Back
                </Button>
              </ButtonGroup>
            )
          }

          {
            errorMessage && (
              <>
                <br/><br/>
                <Alert variant={"danger"}>
                  {errorMessage}
                </Alert>
              </>
            )
          }

        </Form>
      </>
    )
  }
