import Header from "../components/Header";
import {HashRouter, Redirect, Route, Switch} from "react-router-dom";
import {Container} from "react-bootstrap";
import Constant from "../Constant";
import Distributors from "./Distributors";
import Users from "./Users";
import {getFirstMenuItem} from "../components/MenuItems";
import NoPermission from "./NoPermission";
import DistributorDetails from "./DistributorDetails";
import LicenceGeneration from "./LicenceGeneration";
import UserInfo from "./UserInfo";
import PermissionRequired from "../PermissionRequired";
import LicenceSearch from "./LicenceSearch";
import {Permission} from "../Enums";
import LicenceOfDistributor from "./LicenceOfDistributor";
import BatchDetails from "./BatchDetails";
import EditLicence from "./EditLicence";

export default function RouterPage() {
  return (
    <>
      <Header/>
      <Container fluid={true}>
        <div className={"bottomContainer"}>
        <HashRouter>
          <Switch>
            <Route path={Constant.url.DISTRIBUTORS}>
              <PermissionRequired permissions={[Permission.DELIVERY]}>
                <Distributors/>
              </PermissionRequired>
            </Route>
            <Route path={Constant.url.DISTRIBUTOR}>
              <PermissionRequired permissions={[Permission.DELIVERY, Permission.DISTRIBUTOR]}>
                <DistributorDetails/>
              </PermissionRequired>
            </Route>
            <Route path={Constant.url.LICENCE_GENERATION}>
              <PermissionRequired permissions={[Permission.DELIVERY]}>
                <LicenceGeneration/>
              </PermissionRequired>
            </Route>
            <Route path={Constant.url.EDIT_LICENCE}>
              <PermissionRequired permissions={[Permission.DELIVERY]}>
                <EditLicence/>
              </PermissionRequired>
            </Route>
            <Route path={Constant.url.USERS}>
              <PermissionRequired permissions={[Permission.ADMIN]}>
                <Users/>
              </PermissionRequired>
            </Route>
            <Route path={Constant.url.LICENCES_OF_DISTRIBUTOR}>
              <PermissionRequired permissions={[Permission.DELIVERY, Permission.DISTRIBUTOR]}>
                <LicenceOfDistributor/>
              </PermissionRequired>
            </Route>
            <Route path={Constant.url.LICENCES}>
              <PermissionRequired permissions={[Permission.SUPPORT]}>
                <LicenceSearch/>
              </PermissionRequired>
            </Route>
            <Route path={Constant.url.USER_INFO}>
              <UserInfo/>
            </Route>
            <Route path={Constant.url.BATCH}>
              <PermissionRequired permissions={[Permission.DISTRIBUTOR]}>
                <BatchDetails/>
              </PermissionRequired>
            </Route>
            {/*<Route path={Constant.url.NO_PERMISSION}>*/}
            {/*  <NoPermission/>*/}
            {/*</Route>*/}
            <Route path={"/"}>
              <UserInfo/>
            </Route>
          </Switch>
        </HashRouter>
        </div>
      </Container>
    </>
  );
}
