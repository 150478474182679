import RouterPage from "./pages/RouterPage";
import LoginPage from "./pages/LoginPage";
import store from "./store";
import Dispatcher from "./dispatcher";
import {FluxEvent} from "./Enums";
import {useEffect, useState} from "react";
import UlpsPortalApiClient from "./UlpsPortalApiClient";

function App() {

  const onHashChange = (evt) => {
    Dispatcher.dispatch({
      contentType: FluxEvent.URL_CHANGED,
      // url: Util.parseHashLocation(evt.newURL)
    });
  };

  const getUserDetails = () => {
    UlpsPortalApiClient.onSessionUpdated();
    if (store.token) {
      UlpsPortalApiClient.getUser().then( response => {

        if (response.data === null) {
          setLogin(false);
        }
        else {
          setLogin(true);
        }
      })
    }
  }

  useEffect(() => {
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, []);

  const [login,setLogin] = useState(false);

  useEffect(() => {
    getUserDetails()
  },[getUserDetails]);

  return (
    <div className="App">

      {login ? <RouterPage /> : <LoginPage onLogin={getUserDetails}/>}
    </div>
  );
}

export default App;
