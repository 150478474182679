import React, {useEffect, useState} from "react";
import Util from "../Util";
import Dispatcher from "../dispatcher";
import {FluxEvent, Permission} from "../Enums";
import {Alert, Button, Form, InputGroup} from "react-bootstrap";
import LicenceDetails from "./LicenceDetails";
import UlpsPortalApiClient from "../UlpsPortalApiClient";
import store from "../store";
import LicenceTable from "../components/LicenceTable";

export default function LicenceSearch() {
  const [id, setId] = useState(Util.getQuerySearch('id'));
  const [getSearch, setSearch] = useState();
  const [isSearching, setSearching] = useState(false);
  const [list, setList] = useState(undefined);
  const [error, setError] = useState("");
  const deliveryUser = store.session?.permissions.includes(Permission.DELIVERY);
  const [checkboxUnused, setCheckboxUnused] = useState(() => deliveryUser);
  const [checkboxCustomerInfo, setCheckboxCustomerInfo] = useState(deliveryUser);
  const [checkboxPurchaseOrderId, setCheckboxPurchaseOrderId] = useState(deliveryUser);


  useEffect(() => {
    const d = Dispatcher.register(payload => {
      if (payload.contentType === FluxEvent.URL_CHANGED) {
        setId(Util.getQuerySearch('id'))
      }
    });

    return () => {
      Dispatcher.unregister(d);
    };
  }, []);

  const search = e => {
    e.preventDefault();
    setSearching(true)
    UlpsPortalApiClient.getLicences(getSearch, checkboxCustomerInfo, checkboxUnused, checkboxPurchaseOrderId)
      .then(r => {
        setList(r);
        setError("");
      })
      .catch(e => setError(e))
      .finally( () => setSearching(false));
  }


  if (id) {
    return <LicenceDetails id={id}/>
  }

  return <>
    <h2>ULPS Licence Search</h2>
    <br/>
    <form onSubmit={search}>


      <InputGroup className="mb-2">
        <InputGroup.Prepend>
          <InputGroup.Text>Search for Licence</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control value={getSearch} placeholder={"Licence key, contact name, etc."}
                      onChange={e => setSearch(e.target.value)}/>
        <InputGroup.Append>
          {
            isSearching ? <Button disabled>Searching...</Button> : <Button type={"submit"}>Search</Button>
          }
        </InputGroup.Append>
      </InputGroup>


      <div id={"search"}>
        <div className={"inputGroupLike"}>
          <input type={"checkbox"} disabled
                 checked
                 id={"customer"}
          />
          <label htmlFor={"customer"}>Search in licence and hardware id</label>
        </div>

        <label className={"inputGroupLike"} htmlFor={"customer"}
               onClick={() => setCheckboxCustomerInfo(!checkboxCustomerInfo)}>
          <input type={"checkbox"} checked={checkboxCustomerInfo} id={"customer"}/>

          Search in <abbr title={"Customer details, e-mail and name"}>customer info</abbr>
        </label>

        <label className={"inputGroupLike"} htmlFor={"customer"}
               onClick={() => setCheckboxPurchaseOrderId(!checkboxPurchaseOrderId)}>
          <input type={"checkbox"} checked={checkboxPurchaseOrderId} id={"PurchaseOrderId"}/>
          Search in Purchase Order ID
        </label>


        <label className={"inputGroupLike"} htmlFor={"customer"}
               onClick={() => setCheckboxUnused(!checkboxUnused)}>
          <input type={"checkbox"} checked={checkboxUnused} id={"customer"}/>
          Search in unused licences
        </label>
      </div>
    </form>
    <br/>

    {
      error ? <Alert variant={"danger"}>
          {error.message}
        </Alert> :
        list ? <LicenceTable licences={list} showPurchaseOrderId={true}/>: null
    }

  </>;
}
