export default function NoPermission() {
  return (
    <>
      <h1>Access Denied</h1>

      <p>
        You do not have permission to do this.<br/>
        Please Check your account permissions.<br/>
      </p>

    </>
  )
}
