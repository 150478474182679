const Constant = {
  defaultApiUrl: `https://api.ulps.dev.ultinous.com/default`,
  replaceDelimiter: '@%%@',
  apiUrl: "@%%@ULPS_API_URL@%%@",
  fileNamePrefix: "licence",

  url: {
    LICENCE_GENERATION: "/licence-generation",
    DISTRIBUTORS: "/distributors",
    DISTRIBUTOR: "/distributor",
    USERS: "/users",
    NO_PERMISSION: "/no-permission",
    LICENCES_OF_DISTRIBUTOR: "/licence-of-distributor",
    LICENCES: "/licences",
    EDIT_LICENCE: "/licences/edit",
    BATCH: "/batch",
    USER_INFO: "/user",
  }
}

Constant.apiUrl = Constant.apiUrl.includes(Constant.replaceDelimiter) ? Constant.defaultApiUrl : Constant.apiUrl;

export default Constant;
