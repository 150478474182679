import {Nav} from "react-bootstrap";
import {Permission} from "../Enums";
import store from "../store";
import Constant from "../Constant";

const MenuItems = [
  {
    NAME: "Users",
    LINK: Constant.url.USERS,
    PERMISSION: [Permission.ADMIN]
  },
  {
    NAME: "Distributors",
    LINK: Constant.url.DISTRIBUTORS,
    PERMISSION: [Permission.DELIVERY]
  },
  {
    NAME: "Licences",
    LINK: Constant.url.LICENCES,
    PERMISSION: [Permission.SUPPORT,Permission.DELIVERY]
  }
]

function getPermittedMenuItems() {
  return MenuItems.filter(elem => {
    for (let i = 0; i < elem.PERMISSION.length; i++) {
      if (store.session?.permissions?.includes(elem.PERMISSION[i])) {
        return true;
      }
    }
    return false;
  })
}

export function MyMenuItems() {
  return getPermittedMenuItems().map(elem => <Nav.Link href={"#"+elem.LINK} key={elem.NAME}>{elem.NAME}</Nav.Link>)
}

export function getFirstMenuItem() {
  const items = getPermittedMenuItems();

  if (items.length) {
    return items[0].LINK;
  } else {
    return Constant.url.NO_PERMISSION
  }
}
