import React, {useEffect, useState} from "react";
import Dispatcher from "../dispatcher";
import {FluxEvent} from "../Enums";
import Util from "../Util";
import UlpsPortalApiClient from "../UlpsPortalApiClient";
import {Alert, Spinner} from "react-bootstrap";
import BackButton from "../components/BackButton";
import BatchTable from "../components/BatchTable";

export default function LicenceOfDistributor() {
  const [id, setId] = useState(Util.getQuerySearch('id'))
  const [freshBatches, setFreshBatches] = useState(null);
  const [oldBatches, setOldBatches] = useState(null);
  const [isWaiting, setWaiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    resetBatches();

    const d = Dispatcher.register(payload => {
      if (payload.contentType === FluxEvent.URL_CHANGED) {
        setId(Util.getQuerySearch('id'));
      }
    });

    return () => {
      Dispatcher.unregister(d);
    };
  }, []);

  useEffect(() => {
    resetBatches();
  }, [id])

  const resetBatches = async () => {
    setErrorMessage(null);

    if (id) {
      setWaiting(true);
      try {
        setFreshBatches(await UlpsPortalApiClient.getFreshBatches(id));
        setOldBatches(await UlpsPortalApiClient.getOldBatches(id));
      } catch (e) {
        setErrorMessage(e.message)
      } finally {
        setWaiting(false);
      }
    }
  }

  const DistributorName = () => {
    if (freshBatches?.length || oldBatches?.length) {
      const distributorName = freshBatches.length ? freshBatches[0].distributorName : oldBatches[0].distributorName;
      return " of " + distributorName;
    }
    return null;
  }

  if (!id) return null;

  if (isWaiting) {
    return <Spinner animation="border" role="status"/>
  } else {
    return (
      <>

        {errorMessage && <Alert variant={"danger"}>
          {errorMessage}
        </Alert>}

        <h1>Licences<DistributorName/></h1>

        <h2>Fresh batches</h2>

        {
          freshBatches &&
          <BatchTable batches={freshBatches} />
        }

        <BackButton />

        <h2>Old batches</h2>

        {
          oldBatches &&
          <BatchTable batches={oldBatches} oldBatchMode={true} />
        }

        <BackButton/>
      </>
    )
  }


}
