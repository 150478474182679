import React from "react";
import Constant from "./Constant";
import store from "./store";
import moment from "moment";
import sanitize from "sanitize-filename";

const Util = {};

Util.convertLicenceData = licence => {
  return  {
    licenceValidityDays: licence.licenceValidityDays ? Number(licence.licenceValidityDays) : -1,
    supportValidityDays: licence.supportValidityDays ? Number(licence.supportValidityDays) : -1,
    maxStreams: licence.maxStreams ? Number(licence.maxStreams) : -1,
    maxProductVersion: licence.maxProductVersion.length ? licence.maxProductVersion : "infinite",
    licenceSchema: licence.licenceSchema
  };
}

Util.getQuerySearch = (key, applyOn = window.location.href, defaultTo) => {
  key = key.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
  const regex = new RegExp("[\\?&]" + key + "=([^&#]*)");
  const qs = regex.exec(applyOn);
  return (qs === null) ? defaultTo : qs[1];
};

Util.dayToMs = y => (y * 24 * 60 * 60 * 1000);

Number.prototype.toPadLeft = function (lengthToPadTo = 2, textToPadWith = 0) {
  return (this).toString().padStart(lengthToPadTo, textToPadWith);
};
Util.parseTime = function (strings, ...keys) {
  let acc = strings[0];
  for (let i = 1; i < strings.length; i++) {
    acc += keys[i - 1].toPadLeft() + strings[i]
  }
  return acc;
};

Util.shortenComment = cell => {
  const MAXIMUM_CHARACTERS = 50;
  const MAXIMUM_LINES = 2;

  if (!cell) {
    return "-";
  }

  if (cell.length <= MAXIMUM_CHARACTERS && cell.split("\n").length <= MAXIMUM_LINES) {
    return <div className={"preLine"}>{cell}</div>;
  }

  const ret = [];
  const lines = cell.slice(0, MAXIMUM_CHARACTERS).split("\n").slice(0, MAXIMUM_LINES);
  for (let i = 0; i < lines.length; i++) {
    ret.push(<p key={i}>{lines[i]}{i === lines.length - 1 && "..."}</p>)
  }

  return <div>{ret}</div>;
};

Util.saveCsvFile = (content, fileNameSuffix) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));

  let fileName = Constant.fileNamePrefix;
  if (fileNameSuffix) {
    fileName += "_" + sanitize(fileNameSuffix, {replacement: "_"});
  }
  fileName += ".csv";
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

Util.formatDate = (dateStr) => {
  if (!dateStr) {
    return " - ";
  }
  if (store.localDate) {
    return moment.utc(dateStr).local().format("YYYY-MM-DD HH:mm:ss Z")
  } else {
    return dateStr + " UTC";
  }
}

Util.getValidity = (day, to, short = false) => {
  if (day === -1) {
    return 'infinite';
  }
  if (day && !to) {
    return day + ' days';
  }
  if (!day) {
    return 'infinite (not set)';
  }

  const upTo = new Date(new Date(to).getTime() + Util.dayToMs(day))
  let ret = `${upTo.toISOString().substr(0, 10)}`;
  if (!short) {
    ret += `(${day} days from activation)`
  }
  return ret;
}

export default Util;
