import Constant from "../Constant";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import Util from "../Util";

export default function BatchTable({batches, oldBatchMode = false}) {

  const columns = [
    {
      dataField: 'batchNumber',
      text: 'Batch number',
      sort: true
    }, {
      dataField: 'purchaseOrderId',
      text: 'Purchase order ID',
      sort: true
    },
    {
      dataField: 'createdAt',
      text: 'Created at',
      sort: true,
      formatter: Util.formatDate
    }, {
      dataField: 'licenceCount',
      text: 'Licence count',
      sort: true
    }, {
      dataField: 'comment',
      text: 'Comment',
      formatter: Util.shortenComment
    },
  ];

  if (oldBatchMode) {
    columns.push(
      {
        dataField: 'firstExportedAt',
        text: 'First exported at',
        formatter: Util.formatDate,
        sort: true
      },
      {
        dataField: 'firstExportedByName',
        text: 'First exported by',
      }
    );
  }

  const rowEvents = {
    onClick: (e, row) => {
      window.location.href = `#${Constant.url.BATCH}/?distributorId=${row.distributorId}&batchNumber=${row.batchNumber}`;
    }
  }

  return (
    <div className={"alignLeft"}>
      <BootstrapTable keyField='id'
                      data={batches}
                      columns={columns}
                      hover bootstrap4 striped
                      rowEvents={rowEvents}
      />
    </div>
  )
}
