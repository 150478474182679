import Header from "../components/Header";
import {Alert, Button, Col, Form} from "react-bootstrap";
import {Row} from "react-bootstrap";
import {useState} from "react";
import UlpsPortalApiClient from "../UlpsPortalApiClient";

export default function LoginPage({onLogin}) {
  const [usePw, setPw] = useState("");
  const [useUn, setUn] = useState("");
  const [error,setError] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const submit = e => {
    setSubmitting(true)
    UlpsPortalApiClient.login(useUn,usePw).then( _ => {
      onLogin();
    }).catch( error => {
      setError(error.message || "Unknown Error Occurred.")
    }).finally( _ => {
      setSubmitting(false);
    })
    e.preventDefault();

  }
  return <>
    <Header/>

    <div id={"login"}>
      <div id={"loginInner"}>
      <h2>Login</h2>
      <br/>
      <Form onSubmit={submit}>
        <Form.Group as={Row} controlId="formBasicEmail">
          <Form.Label column sm={"4"}>Username</Form.Label>
          <Col sm="8">
          <Form.Control disabled={isSubmitting} type="text" placeholder="Enter Username" value={useUn} onChange={e=>setUn(e.target.value)}/>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formBasicPassword">
          <Form.Label  column sm={"4"}>Password</Form.Label>
          <Col sm="8">
          <Form.Control disabled={isSubmitting} type="password" placeholder="Password" value={usePw} onChange={e=>setPw(e.target.value)}/>
          </Col>
        </Form.Group>

        { isSubmitting ?
          <Button variant="primary" type="submit" disabled>
            Logging in...
          </Button> :
          <Button variant="primary" type="submit" >
            Submit
          </Button>
        }
      </Form>

    </div>
    {
      error && <><br/><Alert variant={"danger"}>
        {error}
      </Alert></>
    }
    </div>
    </>
}
