import {Alert, Button, ButtonGroup, Form, InputGroup, Spinner} from "react-bootstrap";
import {useRef, useState} from "react";
import UlpsPortalApiClient from "../UlpsPortalApiClient";
import Constant from "../Constant";
import {useHistory} from "react-router-dom";
import {LicenceForm} from "./LicenceForm";
import Util from "../Util";

export default function GenerateLicences({distributorId}) {

  if (!distributorId) {
    console.error("Missing distributorId.")
  }

  const [licence, setLicence] = useState({licenceSchema: "ualarm-offline"});
  const [isWaiting, setWaiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const countRef = useRef();
  const commentRef = useRef();
  const purchaseOrderIdRef = useRef();
  const history = useHistory();

  const handleSubmit = async evt => {
    console.log(evt)
    evt.preventDefault()
    setErrorMessage(null);
    setWaiting(true);
    const batchRequest = {
      ...Util.convertLicenceData(licence),
      count: Number(countRef.current.value),
      purchaseOrderId: purchaseOrderIdRef.current.value,
      comment: commentRef.current.value
    };
    try {
      const data = await UlpsPortalApiClient.generateLicences(distributorId, batchRequest)
      setWaiting(false);
      history.push(`${Constant.url.BATCH}/?distributorId=${distributorId}&batchNumber=${data.batchNumber}&success=true`);
    } catch (e) {
      setErrorMessage(e.message || "Unable to generate licence.")
      setWaiting(false);
    }

  }

  return (
    <>
      <h2>Generate licences for distributor</h2>

      <Form onSubmit={handleSubmit}>

        <InputGroup className="mb-2">
          <InputGroup.Prepend>
            <InputGroup.Text>Number of licences</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="number"
            ref={countRef}
            placeholder="Number of licences"
            min={1}
            required
          />
        </InputGroup>

        <LicenceForm licence={licence} setLicence={setLicence} setLoading={setWaiting}/>

        <InputGroup className="mb-2">
          <InputGroup.Prepend>
            <InputGroup.Text>Purchase order ID</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="text"
            placeholder="Purchase order ID"
            ref={purchaseOrderIdRef}
          />
        </InputGroup>

        <InputGroup className="mb-2">
          <InputGroup.Prepend>
            <InputGroup.Text className={"text-left"}>Comment<br/>(customer or project information)</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Comment (customer or project information)"
            ref={commentRef}
          />
        </InputGroup>

        {
          isWaiting ? (
            <Spinner animation="border"/>
          ) : (
            <ButtonGroup>
              <Button variant="success" type="submit">
                Submit
              </Button>
              <Button
                variant="light"
                onClick={() => history.goBack()}
              >
                Back
              </Button>
            </ButtonGroup>
          )
        }

        {
          errorMessage && (
            <>
              <br/><br/>
              <Alert variant={"danger"}>
                {errorMessage}
              </Alert>
            </>
          )
        }

      </Form>
    </>
  )
}
