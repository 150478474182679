import Constant from "./Constant";
import store from "./store";
import {Permission} from "./Enums";
const axios = require('axios');

class ULPSPortalApiClient {
  constructor(apiUrl) {
    this.apiUrl = apiUrl;

  }

  onSessionUpdated() {
    if (store.token) {
      axios.defaults.headers.common = {'Authorization': `Bearer ${store.token}`}
    }
    else {
      axios.defaults.headers.common = {};
    }

  }

  async login(login, password) {
    try {
      const response = await axios.post(`${this.apiUrl}/login`, {login, password});
      store.token = btoa(response.data.token);
      return true;
    } catch (e) {
      console.log(e.response)
      if(e.response?.text === "[400] Already logged in") {
        await this.logout();
        return await this.login();
      }
      ULPSPortalApiClient.handleError(e)
    }
  }

  async logout() {
    window.location.href="#"
    sessionStorage.clear();
    return await axios.post(`${this.apiUrl}/logout`).then( _ => {
      store.session = null;
      store.token = "";
      return true;
    });
  }

  async getUser() {
    try {
      const response = await axios.get(`${this.apiUrl}/user`);
      if (response.data !== null) {
        const {isAdmin, isDelivery, isSupport, distributorId, name} = response.data;
        store.user = response.data;
        const permissions = [
          isAdmin && Permission.ADMIN,
          isDelivery && Permission.DELIVERY,
          isSupport && Permission.SUPPORT,
          distributorId && Permission.DISTRIBUTOR
        ].filter(e=>e);
        store.session = {permissions};
        return response;
      } else {
        this.logout().then( _ => {
          window.location.reload();
        })
      }
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }

  async getDistributor() {
    try {
      const response = await axios.get(`${this.apiUrl}/distributor`);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }



  async getDistributors() {
    try {
      const response = await axios.get(`${this.apiUrl}/distributors`);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }

  async getDistributorDetails(distributorId) {
    if (!distributorId) return;
    try {
      const response = await axios.get(`${this.apiUrl}/distributor/${distributorId}`);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }

  async generateLicences(distributorId, payload) {
    try {
      const response = await axios.post(`${this.apiUrl}/distributor/${distributorId}/generateLicences`, payload);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }

  async getFreshBatches(distributorId) {
    try {
      const response = await axios.get(`${this.apiUrl}/distributor/${distributorId}/batches/fresh`);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }

  async getOldBatches(distributorId) {
    try {
      const response = await axios.get(`${this.apiUrl}/distributor/${distributorId}/batches/old`);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }

  async getLicences(substr,useCustomerInfo=false,showUnused=false, usePurchaseOrderId=false) {
    try {
      const response = await axios.get(
        `${this.apiUrl}/licences/search/substr/${encodeURIComponent(substr)}/useCustomerInfo/${useCustomerInfo}/showUnused/${showUnused}/usePurchaseOrderId/${usePurchaseOrderId}`);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }

  async getLicence(licenceId) {
    try {
      const response = await axios.get(`${this.apiUrl}/licence/${licenceId}`);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }


  async resetLicence(licenceId) {
    try {
      const response = await axios.post(`${this.apiUrl}/licence/${licenceId}/reset`);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }

  async getBatchDetails(distributorId, batchNumber) {
    if (!distributorId || !batchNumber) return;
    try {
      const response = await axios.get(`${this.apiUrl}/distributor/${distributorId}/batch/${batchNumber}`);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }

  async downloadBatch(distributorId, batchNumber) {
    try {
      const response = await axios.post(`${this.apiUrl}/distributor/${distributorId}/batch/${batchNumber}/download`);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }


  async downloadSingeLicence(licenceId) {
    try {
      const response = await axios.get(`${this.apiUrl}/licence/${licenceId}/download`);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }


  async updateLicence(licenceId, licence) {
    try {
      const response = await axios.put(`${this.apiUrl}/licence/${licenceId}`, licence);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err)
    }
  }

  async getLicenceSchemas() {
    try {
      const response = await axios.get(`${this.apiUrl}/licenceSchema`);
      return response.data;
    } catch (err) {
      ULPSPortalApiClient.handleError(err);
    }
  }


  static handleError(error) {
    console.error(error);
    console.trace(error);
    let message = error?.response?.data ? error.response.data : error.message;
    const TRIM_HTTP_ERROR_CODE = /^\[[0-9]+\]\ /
    message = message.replace(TRIM_HTTP_ERROR_CODE, '');
    throw new Error(message);
  }
}

const clientInstance = new ULPSPortalApiClient(Constant.apiUrl);
export default clientInstance
export {ULPSPortalApiClient as ULPSPortalApiClientClass};
