import {Form, InputGroup} from "react-bootstrap";
import {useEffect, useState} from "react";
import UlpsPortalApiClient from "../UlpsPortalApiClient";
import Util from "../Util";

export function LicenceForm({licence, setLicence, setLoading}) {

  const [licenceSchemas, setLicenceSchemas] = useState(null);
  useEffect(() => {
    setLoading(true);
    UlpsPortalApiClient.getLicenceSchemas().then(result => {
      setLicenceSchemas(result);
      setLoading(false);
    })
  }, []);

  const handleChange = prop => evt => {
    setLicence({...licence, [prop]: evt.target.value});
  }

  const handleNumericChange = prop => evt => {
    const parsed = Number(evt.target.value);
    if (isNaN(parsed))
      return;
    setLicence({...licence, [prop]: evt.target.value});
  }

  const handleCheckboxChange = (prop, infiniteValue = null) => evt => {
    setLicence({...licence, [prop]: evt.target.checked ? infiniteValue : ""});
  }

  function getValidity(days) {
    try {
      const parsedDays = Number(days);
      if(isNaN(parsedDays))
        return "";
      return Util.getValidity(parsedDays, licence.firstActivatedAt, true);
    } catch (e) {
      return "";
    }
  }

  return <>
    <InputGroup className="mb-2">
      <InputGroup.Prepend>
        <InputGroup.Text>Licence schema</InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control as="select" onChange={handleChange("licenceSchema")} value={licence.licenceSchema}>
        {
          licenceSchemas && licenceSchemas.map((sch, idx) => <option key={"licenceSchema" + idx}>{sch}</option>) ||
          <option>Loading...</option>
        }
      </Form.Control>
    </InputGroup>

    <InputGroup className="mb-2">
      <InputGroup.Prepend>
        <InputGroup.Text>Support availability</InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control
        type="number"
        placeholder="Support validity days"
        min={1}
        max={5 * 366}
        value={licence.supportValidityDays}
        onChange={handleChange("supportValidityDays")}
        required
      />
      {licence.firstActivatedAt && licence.supportValidityDays &&
        <InputGroup.Append>
          <InputGroup.Text id="validityHelpText" muted>
            {getValidity(licence.supportValidityDays, licence.firstActivatedAt)}
          </InputGroup.Text>
        </InputGroup.Append>
      }
    </InputGroup>


    <InputGroup className="mb-2">
      <InputGroup.Prepend>
        <InputGroup.Text>Licence available until</InputGroup.Text>
      </InputGroup.Prepend>

      <Form.Control
        type="number"
        placeholder="Licence validity days"
        min={1}
        max={5 * 366}
        value={licence.licenceValidityDays || ""}
        onChange={handleChange("licenceValidityDays")}
        disabled={licence.licenceValidityDays === null}
        required={licence.licenceValidityDays !== null}
      />
      {licence.firstActivatedAt && licence.licenceValidityDays &&
        <InputGroup.Append>
          <InputGroup.Text id="validityHelpText" muted>
            {getValidity(licence.licenceValidityDays, licence.firstActivatedAt)}
          </InputGroup.Text>
        </InputGroup.Append>
      }

      <InputGroup.Append>
        <InputGroup.Checkbox
          type="checkbox"
          label="Infinite"
          checked={licence.licenceValidityDays === null}
          onChange={handleCheckboxChange("licenceValidityDays")}
        />
        <InputGroup.Text>Infinite</InputGroup.Text>
      </InputGroup.Append>

    </InputGroup>

    <InputGroup className="mb-2">
      <InputGroup.Prepend>
        <InputGroup.Text>Maximum streams</InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control
        type="number"
        placeholder="Maximum number of streams"
        min={1}
        value={licence.maxStreams || ""}
        onChange={handleChange("maxStreams")}
        disabled={licence.maxStreams === null}
        required={licence.maxStreams !== null}
      />

      <InputGroup.Append>
        <InputGroup.Checkbox
          label="Infinite"
          checked={licence.maxStreams === null}
          onChange={handleCheckboxChange("maxStreams")}
        />
        <InputGroup.Text>Infinite</InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>

    <InputGroup className="mb-2">
      <InputGroup.Prepend>
        <InputGroup.Text>Maximum version</InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control
        type="text"
        placeholder="Maximum product version"
        onChange={handleChange("maxProductVersion")}
        value={licence.maxProductVersion === null || licence.maxProductVersion === "infinite" ? "" : licence.maxProductVersion}
        disabled={licence.maxProductVersion === "infinite"}
        required={licence.maxProductVersion !== "infinite"}
      />
      <InputGroup.Append>
        <InputGroup.Checkbox
          label="Infinite"
          checked={licence.maxProductVersion === "infinite"}
          onChange={handleCheckboxChange("maxProductVersion", "infinite")}
        />
        <InputGroup.Text>Infinite</InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>

  </>
}
