export class RawEnum {
  /**
   * @description returns an object with key-enum pairs, implementing a regular ENUM class.
   *              The Proxy handler disables the object's default setters and getters.
   *              After constructing an enum instance, it cannot be modified
   * @example new Enum("red","green","blue");
   * @example new Enum(arrayOfLeaders);
   * @param enumArr : [string]|* An array of strings from which the Enums will be constructed.
   * @return {{string}|*} key and the enum's description will be identical
   */
  constructor(enumArr) {
    if (!Array.isArray(enumArr)) {
      enumArr = [...arguments];
    }

    const handler = {
      get(target, name) {
        if (typeof target[name] != "undefined") {
          return target[name];
        }
        // console.trace(name)
        //throw new Error(`No such enumerator: ${name}`);
        // TODO FIX WTF $$typeof
      },
      set() {
        throw new Error("Cannot add/update properties on an Enum instance after it is defined");
      }
    };

    let obj = {};
    enumArr.forEach(element => obj[element] = this.wrapElement(element));

    return Object.freeze(new Proxy(obj, handler));
  }

  wrapElement(element) {
    return element
  }
}
export class Enum extends RawEnum {
  wrapElement(element) {
    return Symbol(element);
  }
}

export const Permission = new RawEnum(
  "USER",
  "ADMIN",
  "DELIVERY",
  "DISTRIBUTOR",
  "SUPPORT");

export const FluxEvent = new Enum(
  "URL_CHANGED",
  "SESSION_CHANGED",
  "PERMISSIONS_CHANGED",
  "USER_CHANGED"
);

export const LicenceRestrictionsMode = new Enum(
  "BATCH",
  "LICENCE"
)
