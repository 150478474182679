import {Alert, Button, Table} from "react-bootstrap";
import BatchTable from "./BatchTable";
import Util from "../Util";
import React from "react";
import Constant from "../Constant";

export default function DistributorInfo({distributorInfo, freshBatches}) {

  if (!distributorInfo) {
    return null;
  }

  return (
    <>
      <h2>Distributor details</h2>

      {distributorInfo.disabledAt && (
        <Alert variant={"warning"}>
          This distributor is disabled.
        </Alert>
      )}

      <Table striped bordered>
        <tbody>
        <tr>
          <td>Id</td>
          <td>{distributorInfo.id}</td>
        </tr>
        <tr>
          <td>Name</td>
          <td>{distributorInfo.name}</td>
          {/*<td><Form.Control type="text" value={distributor.name} onChange={handleChange("name")}/></td>*/}
        </tr>

        <tr>
          <td>Country</td>
          <td>{distributorInfo.country}</td>
          {/*<td><Form.Control type="text" value={distributor.country} onChange={handleChange("country")}/></td>*/}
        </tr>
        <tr>
          <td>Contact name</td>
          <td>{distributorInfo.contactName}</td>
          {/*<td><Form.Control type="text" value={distributor.contactName} onChange={handleChange("contactName")}/></td>*/}
        </tr>
        <tr>
          <td>Contact email</td>
          <td>{distributorInfo.email}</td>
          {/*<td><Form.Control type="text" value={distributor.contactEmail} onChange={handleChange("contactEmail")}/>*/}
          {/*</td>*/}
        </tr>
        <tr>
          <td>Phone</td>
          <td>{distributorInfo.phone}</td>
          {/*<td><Form.Control type="text" value={distributor.phone} onChange={handleChange("phone")}/></td>*/}
        </tr>
        { distributorInfo.contactDescription && (
          <tr>
            <td>Contact description</td>
            <td>{distributorInfo.contactDescription}</td>
          </tr>
        )}
        <tr>
          <td>Web page</td>
          <td><a href={distributorInfo.webpage}>{distributorInfo.webpage}</a></td>
          {/*<td><Form.Control type="text" value={distributor.webpage} onChange={handleChange("webpage")}/></td>*/}
        </tr>
        <tr>
          <td>Created at</td>
          <td>{Util.formatDate(distributorInfo.createdAt)}</td>
        </tr>
        <tr>
          <td>Updated at</td>
          <td>{Util.formatDate(distributorInfo.updatedAt)}</td>
        </tr>
        <tr>
          <td>Batch count</td>
          <td>{distributorInfo.batchCount}</td>
        </tr>

        </tbody>
      </Table>
      <br/>
      <h2>Fresh batches:</h2>
      <BatchTable batches={freshBatches || []} distributorId={distributorInfo.id}/>
      <p><Button href={`#${Constant.url.LICENCES_OF_DISTRIBUTOR}/?id=${distributorInfo.id}`}>View all batches</Button></p>

    </>
  )
}
