import {Table} from "react-bootstrap";
import Util from "../Util";
import {LicenceRestrictionsMode} from "../Enums";


const baseValidity = base => {
    if (!base) return 'infinite (not set)';
    if (base === -1) return 'infinite';
    return base;
}

function LicenceValidity({details}) {
    const until = details.firstActivatedAt ? "until" : "from activation";
    return (
      <>
        <tr>
          <td>Licence schema</td>
          <td>{details.licenceSchema || "unknown"}</td>
        </tr>
        <tr>
              <td>Support valid {until}</td>
              <td>{
                  Util.getValidity(details.supportValidityDays, details.firstActivatedAt)
              }</td>
          </tr>
          <tr>
              <td>Licence valid {until}</td>
              <td>{
                  Util.getValidity(details.licenceValidityDays, details.firstActivatedAt)
              }</td>
          </tr>
      </>
    )
}

function BatchValidity({details}) {
    return (
      <>
          <tr>
              <td>Support validity days</td>
              <td>{baseValidity(details.supportValidityDays)}</td>
          </tr>
          <tr>
              <td>Licence validity days</td>
              <td>{baseValidity(details.licenceValidityDays)}</td>
          </tr>
      </>
    )
}

export default function LicenceRestrictions({details, mode}) {
    return (
      <Table striped bordered>
          <thead>
          <tr>
              <th colSpan={2}>Licence Restrictions</th>
          </tr>
          </thead>
          <tbody>
          {mode === LicenceRestrictionsMode.LICENCE && <LicenceValidity details={details}/>}
          {mode === LicenceRestrictionsMode.BATCH && <BatchValidity details={details}/>}
          <tr>
              <td>Max product version</td>
              <td>{details.maxProductVersion || "unknown"}</td>
          </tr>
          <tr>
              <td>Max streams (cameras)</td>
              <td>{details.maxStreams === -1 ? "infinite" : details.maxStreams || "infinite (not set)"}</td>
          </tr>
          </tbody>
      </Table>
    )
}
