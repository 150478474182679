import NoPermission from "./pages/NoPermission";
import store from "./store";
import React, {useState, useEffect} from "react";
import Dispatcher from "./dispatcher";
import {FluxEvent} from "./Enums";

export default function PermissionRequired({requiredPermissions, silent, children}) {

  const [currentPermissions, setCurrentPermissions] = useState(store.session?.permissions);

  useEffect(() => {
    const d = Dispatcher.register(payload => {
      if (payload.contentType === FluxEvent.PERMISSIONS_CHANGED) {
        setCurrentPermissions(store.session?.permissions)
      }
    });

    return () => {
      Dispatcher.unregister(d);
    };
  }, [])


  if (currentPermissions === undefined) {
    return null;
  }

  if(!requiredPermissions) {
    return children;
  }

  for (let i = 0; i < requiredPermissions.length; i++) {
    if (store.session?.permissions.includes(requiredPermissions[i])) {
      return children;
    }
  }

  if (silent) {
    return null;
  }

  return <NoPermission/>;
}
