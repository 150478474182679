import {useEffect, useState} from "react";
import Util from "../Util";
import {Alert, Button, ButtonGroup, Spinner, Table} from "react-bootstrap";
import ULPSPortalApiClient from "../UlpsPortalApiClient";
import Constant from "../Constant";
import LicenceRestrictions from "../components/LicenceRestrictions";
import store from "../store";
import {LicenceRestrictionsMode, Permission} from "../Enums";
import PermissionRequired from "../PermissionRequired";
import {useHistory} from "react-router-dom";

function isDelivery() {
  return store.session?.permissions?.indexOf(Permission.DELIVERY) !== -1;
}

export default function LicenceDetails({id}) {

  const history = useHistory();

  const [details, setDetails] = useState();
  const [error, setError] = useState(false);
  useEffect(() => {
    ULPSPortalApiClient.getLicence(id).then(r => {
      setDetails(r);
    }).catch(_ => {
      setError(true);
    })
  }, [id]);

  useEffect(() => {
    if (details) {
      getLicenceStatus()
    }

  }, [details])

  const getLicenceStatus = (getSupport = false) => {
    const licenceOrSupportValidity = getSupport ? details.supportValidityDays : details.licenceValidityDays
    return !licenceOrSupportValidity ||
      (new Date(details.firstActivatedAt).getTime() + Util.dayToMs(licenceOrSupportValidity)) > new Date().getTime()
  }

  const resetLicence = () => {
    if (window.confirm("This will remove the current hardwareId, contact details from this licence.\nPress OK to continue.")) {
      ULPSPortalApiClient.resetLicence(id).then(r => {
        setDetails(r);
        window.scrollTo(0, 0);
      }).catch(_ => {
        setError(true);
      })
    }
  }

  const downloadLicence = async () => {
    try {
      const data = await ULPSPortalApiClient.downloadSingeLicence(id)
      setError(null);
      Util.saveCsvFile(data);
    } catch (e) {
      setError(e)
    }
  }

  return <>
    <Button className={"floatyBackButton"} onClick={history.goBack}>Go back</Button>
    <h2>Licence</h2>

    {
      error ? <Alert variant="danger">Cannot get licence info</Alert> : details ? <>        <PermissionRequired
        requiredPermissions={[Permission.DELIVERY]} silent={true}>
        <ButtonGroup>
          <Button variant={"success"} onClick={downloadLicence}>Download</Button>
          <Button variant={"primary"} href={`#${Constant.url.EDIT_LICENCE}?id=${id}`}>Edit</Button>
          {
            details.firstActivatedAt &&
            <Button variant={"danger"} onClick={resetLicence}>Reset</Button>
          }
        </ButtonGroup>
      </PermissionRequired>
        <div className={"fixedCells"}>
          {details.firstActivatedAt ? <span className={"halfWidthAlert"}>
        {getLicenceStatus() ? <Alert variant="success">Licence is active and valid.</Alert> :
          <Alert variant="danger">Licence has expired.</Alert>}
            {getLicenceStatus(true) ? <Alert variant="success">Support is active.</Alert> :
              <Alert variant="danger">Support has expired.</Alert>}
      </span> : <Alert variant="info">Licence is not activated yet.</Alert>}
          <br/>
          <Table striped bordered>
            <thead>
            <tr>
              <th colSpan={2}>Key</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Activation Key</td>
              <td className={"monospace"}>{details.activationKey}</td>
            </tr>
            <tr>
              <td>Hardware Id on the activated device</td>
              <td className={details.hardwareId && "monospace"}>{details.hardwareId || "Not activated yet"}</td>
            </tr>
            {
              isDelivery() && <tr>
                <td>Parent batch</td>
                <td><a href={`#/batch/?distributorId=${details.distributorId}&batchNumber=${details.batchNumber}`}>
                  Batch {details.batchNumber || "unknown"}</a></td>
              </tr>
            }
            </tbody>
          </Table>

          <Table striped bordered>
            <thead>
            <tr>
              <th colSpan={2}>Customer Details</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Distributor</td>
              <td>
                {
                  isDelivery() ? <a
                      href={`#${Constant.url.DISTRIBUTOR}/?id=${details.distributorId}`}>{details.distributorName || "unknown"}</a> :
                    details.distributorName || "unknown"
                } ({details.distributorCountry || "Unknown country"})
              </td>
            </tr>
            <tr>
              <td>Purchase order ID</td>
              <td>{details.purchaseOrderId || "unknown"}</td>
            </tr>
            <tr>
              <td>Customer name</td>
              <td>{details.customerName || "unknown"}</td>
            </tr>
            <tr>
              <td>Contact Name</td>
              <td>{details.contactName || "unknown"}</td>
            </tr>
            <tr>
              <td>Contact Email</td>
              <td>{details.contactEmail || "unknown"}</td>
            </tr>
            </tbody>
          </Table>

          <LicenceRestrictions details={details} mode={LicenceRestrictionsMode.LICENCE}/>

          <Table striped bordered className={"w25"}>
            <thead>
            <tr>
              <th colSpan={4}>Licence Date</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Created</td>
              <td>{Util.formatDate(details.createdAt)}</td>
            </tr>
            <tr>
              <td>Updated</td>
              <td>{Util.formatDate(details.updatedAt)}</td>
            </tr>
            <tr>
              <td>First Activated</td>
              <td>{Util.formatDate(details.firstActivatedAt)}</td>
            </tr>
            <tr>
              <td>Last Activated</td>
              <td>{Util.formatDate(details.lastActivatedAt)}</td>
            </tr>
            </tbody>
          </Table>

        </div>
      </> : <><br/><br/><Spinner animation="border"/></>
    }
  </>;
}
