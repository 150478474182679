import {FluxEvent} from "./Enums";
import ULPSPortalApiClient from "./UlpsPortalApiClient";
import Dispatcher from "./dispatcher";

class Store {
  #session = null;
  #token = sessionStorage.getItem('ulps-session');
  #localDate = localStorage.getItem("localDate") === "true";
  #user = null;
  //{name: "asd", token: "token", permissions: [Permission.ADMIN, Permission.DELIVERY]};

  get session() {
    return this.#session;
  }

  set user(user) {
    this.#user = user;
    Dispatcher.dispatch({
      contentType: FluxEvent.USER_CHANGED
    });
  }

  get user() {
    return this.#user;
  }

  set session(session) {
    this.#session = session;
    Dispatcher.dispatch({
      contentType: FluxEvent.SESSION_CHANGED
    });

    Dispatcher.dispatch({
      contentType: FluxEvent.PERMISSIONS_CHANGED
    })
  }

  get token() {
    return this.#token;
  }
  set token(token) {
    sessionStorage.setItem('ulps-session',token);
    this.#token = token;
    ULPSPortalApiClient.onSessionUpdated();
  }

  get localDate() {
    return this.#localDate;
  }

  set localDate(value) {
    this.#localDate = value;
    window.localStorage.setItem("localDate", value.toString())
  }
}

const store = new Store();


export default store;
