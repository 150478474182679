import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Util from "../Util";
import {Permission} from "../Enums";
import UlpsPortalApiClient from "../UlpsPortalApiClient";
import {Alert, Button, ButtonGroup, Spinner, Table} from "react-bootstrap";
import PermissionRequired from "../PermissionRequired";
import LicenceTable from "../components/LicenceTable";
import Constant from "../Constant";

export default function BatchDetails() {

  const params = new URLSearchParams(useLocation().search)
  const distributorId = params.get('distributorId')
  const batchNumber = params.get('batchNumber')
  const success = !!params.get('success')

  const [batchDetails, setBatchDetails] = useState(null)
  const [isWaiting, setWaiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    resetBatch();
  }, [batchNumber, distributorId])

  const resetBatch = async () => {
    setErrorMessage(null);
    if (distributorId && batchNumber) {
      setWaiting(true);
      try {
        setBatchDetails(await UlpsPortalApiClient.getBatchDetails(distributorId, batchNumber));
        setWaiting(false);
      } catch (e) {
        setErrorMessage(e.message)
        setWaiting(false);
      }
    }
  }

  function formatExported(exportedAt, exportedBy) {
    if (!exportedAt)
      return "-";

    return <>{
      Util.formatDate(exportedAt)} ({exportedBy || "-"})
    </>;
  }

  const downloadBatch = async () => {
    setErrorMessage(null);
    setWaiting(true);
    try {
      const data = await UlpsPortalApiClient.downloadBatch(distributorId, batchNumber)
      setWaiting(false);
      setErrorMessage(null);
      Util.saveCsvFile(data, batchDetails.purchaseOrderId);
      resetBatch()
    } catch (e) {
      setErrorMessage(e.message)
      setWaiting(false);
    }
  }

  const BatchInfoSheet = () => {
    return (
      <>
        <Table striped bordered>
          <thead>
          <tr>
            <th colSpan={2}>Details</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Distributor Name</td>
            <td>{batchDetails.distributorName}</td>
          </tr>
          <tr>
            <td>Batch Number</td>
            <td>{batchDetails.batchNumber}</td>
          </tr>
          {
            batchDetails.purchaseOrderId && <tr>
              <td className={"nowrap"}>Purchase order ID</td>
              <td>{batchDetails.purchaseOrderId}</td>
            </tr>
          }
          {
            batchDetails.comment && <tr>
              <td>Comment</td>
              <td className={"pre alignLeft"}>{batchDetails.comment}</td>
            </tr>
          }
          <tr>
            <td>Created at</td>
            <td>{Util.formatDate(batchDetails.createdAt)}</td>
          </tr>
          <tr>
            <td>First exported</td>
            <td>{formatExported(batchDetails.firstExportedAt, batchDetails.firstExportedByName || batchDetails.firstExportedBy)}</td>
          </tr>
          {
            batchDetails.lastExportedAt && <tr>
              <td>Last exported</td>
              <td>{formatExported(batchDetails.lastExportedAt, batchDetails.lastExportedByName || batchDetails.lastExportedBy)}</td>
            </tr>
          }
          <tr>
            <td>Licence count</td>
            <td>{batchDetails.licenceCount}</td>
          </tr>
          </tbody>
        </Table>

        <ButtonGroup aria-label="Basic example">
          <PermissionRequired requiredPermissions={[Permission.DISTRIBUTOR, Permission.DELIVERY]} silent={true}>
            <Button variant="success" onClick={downloadBatch}>Download licences</Button>
          </PermissionRequired>
          <Button variant={"secondary"} href={`#${Constant.url.DISTRIBUTOR}/?id=${distributorId}`}>Back</Button>
        </ButtonGroup>

      </>
    )
  }

  if (!distributorId || !batchNumber) {
    return null;
  }

  return (
    <>
      {success && <Alert variant={"success"}>Batch generated successfully.</Alert>}
      <h1>Batch details</h1>
      {batchDetails && <>
        <BatchInfoSheet/>
        <br/><br/>
        <LicenceTable licences={batchDetails.licences} hideColumns={"contactName"}/>
      </>}

      {isWaiting && <div><Spinner animation="border" role="status"/></div>}

      {errorMessage && <Alert variant={"danger"}>
        {errorMessage}
      </Alert>}


    </>
  )
}
