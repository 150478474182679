import {Container, Nav, Navbar} from "react-bootstrap";
import {MyMenuItems} from "./MenuItems";
import store from "../store";
import UlpsPortalApiClient from "../UlpsPortalApiClient";
import {useEffect, useState} from "react";
import Dispatcher from "../dispatcher";
import {FluxEvent} from "../Enums";

export default function Header() {
  const [name, setName] = useState(store.user?.name)

  const logout = () => {
    UlpsPortalApiClient.logout().then( _ => {
      window.location.reload();
    })
  }

  useEffect(() => {
    const d = Dispatcher.register(payload => {
      if (payload.contentType === FluxEvent.USER_CHANGED) {
        setName(store.user?.name);
      }
    });

    return () => {
      Dispatcher.unregister(d);
    };
  }, []);

  return (
    <Navbar bg="dark" variant={"dark"} expand="lg">
      <Container>
        <Navbar.Brand href="#">ULPS Portal</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <MyMenuItems/>
          </Nav>

          {store.token &&
          <>
            {store.user?.name && <Nav.Link className={"gray"} href="#/">[{store.user?.name}]</Nav.Link>}
            <Nav.Link className={"gray"} onClick={logout}>Logout</Nav.Link>
          </>

          }
        </Navbar.Collapse>

      </Container>
    </Navbar>
  )
}

