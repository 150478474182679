 import React, {useEffect, useState} from "react";
import UlpsPortalApiClient from "../UlpsPortalApiClient";
import store from "../store";
import {Alert, Form, Table} from "react-bootstrap";
import Dispatcher from "../dispatcher";
import {FluxEvent} from "../Enums";
import DistributorInfo from "../components/DistributorInfo";

export default function UserInfo() {
  const [userInfo, setUserInfo] = useState(store.user);
  const [permissions, setPermissions] = useState(store.session?.permissions);
  const [distributorInfo, setDistributorInfo] = useState(null);
  const [freshBatches, setFreshBatches] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [localDate, setLocalDate] = useState(store.localDate);

  useEffect(() => {
    const d = Dispatcher.register(payload => {
      if (payload.contentType === FluxEvent.SESSION_CHANGED) {
        setPermissions(store.session?.permissions)
      }
    });

    if (!!userInfo?.distributorId) {
      UlpsPortalApiClient.getDistributor()
            .then(setDistributorInfo)
            .catch(setErrorMessage);

      UlpsPortalApiClient.getFreshBatches(userInfo?.distributorId).then(data => {
        setFreshBatches(data);
      }).catch(err => {
        setErrorMessage(err);
      })
    }



    return () => {
      Dispatcher.unregister(d);
    };

  }, [])

  if (!userInfo) {
    return null;
  }

  return (
    <>
      <h1>User information</h1>

      <Table striped bordered>
        <tbody>
        <tr>
          <td>ID</td>
          <td>{userInfo.id}</td>
        </tr>
        <tr>
          <td>Login name</td>
          <td>{userInfo.login}</td>
        </tr>
        <tr>
          <td>Full name</td>
          <td>{userInfo.name}</td>
        </tr>
        <tr>
          <td>E-mail</td>
          <td>{userInfo.email}</td>
        </tr>
        <tr>
          <td>Permissions</td>
          <td>{permissions}</td>
        </tr>
        </tbody>
      </Table>

      <Form>
        <Form.Check
          type="switch"
          id="localDateSwitch"
          label="Show timestamps with local timezone"
          checked={localDate}
          onClick={() => {
            setLocalDate(store.localDate = !localDate)
          }}
        />
      </Form>

      <DistributorInfo distributorInfo={distributorInfo} freshBatches={freshBatches} />

      {errorMessage && <Alert variant={"danger"}>
        {errorMessage}
      </Alert>}

    </>
  )
}
