import {useEffect, useState} from "react";
import Util from "../Util";
import Dispatcher from "../dispatcher";
import {FluxEvent} from "../Enums";
import GenerateLicences from "../components/GenerateLicences";

export default function LicenceGeneration() {
  const [id, setId] = useState(Util.getQuerySearch('id'))

  useEffect(() => {
    const d = Dispatcher.register(payload => {
      if (payload.contentType === FluxEvent.URL_CHANGED) {
        setId(Util.getQuerySearch('id'))
      }
    });

    return () => {
      Dispatcher.unregister(d);
    };
  }, []);

  if (id) {
    return <GenerateLicences distributorId={id} />
  }
}
