import {useHistory} from "react-router-dom";
import {Button} from "react-bootstrap";
import React from "react";

export default function BackButton() {
  const history = useHistory();

  return (
    <Button
      variant="secondary"
      onClick={() => {
        history.goBack()
      }}
    >
      Back
    </Button>
  )
}
